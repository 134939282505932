import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layouts/layout';
import BreadCrumb from '../components/ProductMain/BreadCrumb';
import ProductMain from '../components/ProductMain/ProductMain';

const cream = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rose And Gold Face Cream For Skin Brightening with SPF-15</title>
        <meta name="facebook-domain-verification" content="7zavsnqe2ti2yx2faj448nyhtkrxg5" />
        <meta name="title" content="Rose And Gold Face Cream For Skin Brightening with SPF-15" />
        <meta
          name="description"
          content="Rose And Gold Face Cream is a rich combination of clinically proven Plant actives, Vitamins, Alpha-Hydroxy acid (AHA) & UV filters. It helps reduce hyper-pigmentation & dark spots and evens out skin tone"
        />
        <meta
          name="keywords"
          content="Rose And Gold Face Cream, Skin Brightening, SPF-15 cream, Day Cream"
        />
        {/* Global site tag (gtag.js) - Google Analytics  */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-1LB4M0W7V9" />
        <script src={withPrefix('js/script.js')} type="text/javascript" />
      </Helmet>
      <Layout>
        <BreadCrumb productSelected="cream" />
        <ProductMain productSelected="cream" />
      </Layout>
    </>
  );
};

export default cream;
